var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
(function ($) {
  $(document).on('powerreviews-snippet-init', '.js-pr-product-ui-grid', function () {
    var $context = $(this);
    var $products = $('.js-product-ui', $context);

    Drupal.behaviors.productReviewSnippetV1.loadReviewSnipetData($products);
  });

  Drupal.behaviors.productReviewSnippetV1 = {
    attach: function (context) {
      var self = this;

      if (this.attached) {
        return;
      }
      this.attached = true;
      var $products = $('.js-product-ui', context);

      self.loadReviewSnipetData($products);
    },

    loadReviewSnipetData: function ($products) {
      var self = this;
      var productIds = [];
      var base_url = Drupal.settings.power_reviews.api_url;
      var merchant_id = Drupal.settings.power_reviews.merchant_id;
      var api_key = Drupal.settings.power_reviews.api_key;
      var locale = Drupal.settings.power_reviews.locale;
      var locales = $.cookie('LOCALE');
      var $pdpMainproduct = $products.filter('.js-spp-content');

      if (locales) {
        locales = locales.toLowerCase();
        if (typeof Drupal.settings.power_reviews[locales] !== 'undefined' && typeof Drupal.settings.power_reviews[locales]['merchant_id'] !== 'undefined' && typeof Drupal.settings.power_reviews[locales]['locale'] !== 'undefined') {
          merchant_id = Drupal.settings.power_reviews[locales]['merchant_id'];
          locale = Drupal.settings.power_reviews[locales]['locale'];
        }
      }

      $products.each(function () {
        var $product = $(this);
        var product_id = $product.data('product-id');

        if (!!product_id) {
          var product = prodcat.data.getProduct(product_id);

          if (!!product) {
            productIds.push(product.PROD_BASE_ID);
            $product.data('productReviewId', product.PROD_BASE_ID).attr('data-product-review-id', product.PROD_BASE_ID);
          }
        }
      });
      if (!$.isEmptyObject(productIds)) {
        productIds = productIds.join(',');
        const api_url = `${base_url}/m/${merchant_id}/l/${locale}/product/${productIds}/snippet?apikey=${api_key}`;

        $.ajax({
          url: api_url,
          success: function (data) {
            if (!data.results || !data.results.length) {
              self.initReviewSnippetonMainProduct($pdpMainproduct);
              return;
            }
            data.results.forEach(function (result) {
              self.initReviewSnippet($products.filter('[data-product-review-id="' + result.page_id + '"]'), result);
            });
            setTimeout(function() {
              // Make sure we call the main product once even if it's not within the results.
              self.initReviewSnippetonMainProduct($pdpMainproduct);
            }, 200);
          }
        });
      }
    },
    initReviewSnippetonMainProduct: function($pdpMainproduct) {
      var self = this;
      if (!$pdpMainproduct.hasClass('review-snippet-loaded-processed')) {
        self.initReviewSnippet($pdpMainproduct, null);
      }
    },
    initReviewSnippet: function ($product, result) {
      if ($product.length < 1) {
        return;
      }
      $product.once('review-snippet-loaded', function () {
        $product.trigger('review-snippet-loaded', { result: result });
      });
      var $ratingContainer = $('.js-product-brief__rating', $product);
      var $ratingTotal = $('.js-product-brief__rating-total', $product);
      var $rating = $('.product__rating', $product);
      var $rating_no_results = $('.product__rating-no-results', $product);
      var $rating_stars = $('.js-product__rating-percentage', $rating);
      var total_count = result?.rollup?.review_count || 0;
      var avg_rating = result?.rollup?.average_rating || 0;
      var rating_width = (avg_rating / 5) * 100;
      var $rating_count = $('.js-product__rating-count', $rating);
      var $rating_avg = $('.js-product__rating-average', $rating);

      if (total_count === 0) {
        // Show no results
        $rating_no_results.removeClass('hidden');
        $rating_count.addClass('hidden');
      } else {
        // Update width of stars to reflect scoring.
        $ratingContainer.removeClass('hidden');
        $rating_stars.width(rating_width + '%');
        $rating_count.html(total_count);
        $ratingTotal.removeClass('hidden');
      }
      if (avg_rating === 0) {
        // Show no results
        $rating_no_results.removeClass('hidden');
        $rating_avg.addClass('hidden');
      } else {
        $rating_avg.html(avg_rating);
      }
      $rating.removeClass('hidden');
    },
    attached: false
  };
})(jQuery);
